<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="550"
    persistent
  >
    <v-form>
      <v-card>
        <!-- form title -->
        <v-toolbar dark color="amber" class="elevation-0 px-3">
          <span class="headline">Actualizar local</span>
        </v-toolbar>
        <!-- form content -->
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="nombre" :validator="$v.local.name">
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Nombre del local"
                    placeholder="Nombre del local. Ej.: Local 1"
                    required
                    filled
                    rounded
                    v-model="local.name"
                  />
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group
                  name="descripcion"
                  :validator="$v.local.description"
                >
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Descripción"
                    placeholder="Ingrese la descripción"
                    required
                    filled
                    rounded
                    v-model="local.description"
                  />
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="capacidad" :validator="$v.local.capacity">
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Capacidad del local"
                    placeholder="Capacidad del local. Ej.: 45"
                    type="number"
                    min="0"
                    required
                    filled
                    rounded
                    v-model="local.capacity"
                  />
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <form-group name="local" :validator="$v.local.area_id">
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :loading="areas.isLoading"
                    :items="areas.data"
                    item-text="name"
                    item-value="id"
                    label="Areas"
                    required
                    filled
                    rounded
                    v-model="local.area_id"
                  ></v-select>
                </form-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn
            color="amber"
            dark
            depressed
            :loading="isLoading"
            @click="submit"
            >Actualizar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {
  required,
  maxLength,
  numeric,
  minValue,
  integer,
} from "vuelidate/lib/validators";
import localsRepository from "@/repositories/localsRepository";

export default {
  name: "UpdateLocal",

  data() {
    return {
      isLoading: false,
      dialog: false,
    };
  },
  props: {
    local: {
      type: Object,
    },
    areas: {
      type: Object,
    },
  },
  methods: {
    update() {
      this.isLoading = true;
      localsRepository
        .updateLocal(this.local.id, this.local)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data, hidding modal and cleaning selection if operation has been completed
          if (data.status) {
            this.$emit("update", {
              obj: data.data,
              id: this.local.id,
            });
            this.$emit("clean-selection");
            this.closeModal();
          }
        })
        .catch((error) => {
          // Something happened in setting up the request and triggered an Error
          console.error("Error", error.message);
          this.fireToast({
            icon: "error",
            title: "Ha ocurrido un error inesperado 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.update();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
    },
  },
  validations: {
    local: {
      name: {
        required,
        maxLength: maxLength(255),
      },
      description: {
        required,
        maxLength: maxLength(255),
      },
      capacity: {
        required,
        numeric,
        integer,
        minValueValue: minValue(0),
      },
      area_id: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
};
</script>
