import { PedagogicalDiaryService } from "@/core/services/apis.service";

const RESOURCE = "areas"

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllAreas = () => {
    return PedagogicalDiaryService.get(`${RESOURCE}`);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getArea = (id) => {
    return PedagogicalDiaryService.get(`${RESOURCE}`, id);
};

/**
 * POST request to create a new duration type
 * @param payload
 * @returns {*}
 */
export const createArea = (payload) => {
    return PedagogicalDiaryService.post(`${RESOURCE}`, payload, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
};

/**
 * PUT request to update a specific duration type
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateArea = (id, payload) => {
    return PedagogicalDiaryService.put(`${RESOURCE}`, id, payload, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
};

/**
 * DELETE request to delete the specified duration type
 * @param id
 * @returns {*}
 */
export const deleteArea = (id) => {
    return PedagogicalDiaryService.delete(`${RESOURCE}/${id}`);
};

export default {
    getAllAreas,
    getArea,
    createArea,
    updateArea,
    deleteArea,
};
