import { PedagogicalDiaryService } from "@/core/services/apis.service";

const RESOURCE = "locals"

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllLocals = () => {
    return PedagogicalDiaryService.get(`${RESOURCE}`);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getLocal = (id) => {
    return PedagogicalDiaryService.get(`${RESOURCE}`, id);
};

/**
 * POST request to create a new duration type
 * @param payload
 * @returns {*}
 */
export const createLocal = (payload) => {
    return PedagogicalDiaryService.post(`${RESOURCE}`, payload);
};

/**
 * PUT request to update a specific duration type
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateLocal = (id, payload) => {
    return PedagogicalDiaryService.put(`${RESOURCE}`, id, payload);
};

/**
 * DELETE request to delete the specified duration type
 * @param id
 * @returns {*}
 */
export const deleteLocal = (id) => {
    return PedagogicalDiaryService.delete(`${RESOURCE}/${id}`);
};

export default {
    getAllLocals,
    getLocal,
    createLocal,
    updateLocal,
    deleteLocal,
};
