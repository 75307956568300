<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Gestión de locales
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podra visualizar, crear, actualizar y eliminar todos los
              locales.</span
            >
          </h3>
        </div>
        <div class="card-toolbar">
          <!--begin::Button New Record-->
          <v-btn
            elevation="0"
            color="primary"
            @click="showMdlCreate"
            v-if="existInArray('Agregar', currentPageActions)"
          >
            <span class="svg-icon svg-icon-md svg-icon-white mr-1">
              <inline-svg src="media/svg/icons/Home/Book-open.svg"></inline-svg>
            </span>
            Crear local
          </v-btn>
          <!--end::Button New Record-->
        </div>
      </div>
      <div class="card-body">
        <!-- begin::Search Form -->
        <div class="mb-5">
          <div class="row align-items-center">
            <div class="col-lg-9 col-xl-8">
              <div class="row align-items-center">
                <div class="col-md-4 my-2 my-md-0">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    prepend-icon=""
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Buscar..."
                    v-model="locals.search"
                  ></v-text-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::Search Form -->
        <!--begin: Datatable-->
        <v-card>
          <v-data-table
            :headers="locals.headers"
            :items="locals.data"
            :search="locals.search"
            :items-per-page="10"
            :loading="locals.isLoading"
          >
            <!-- begin: Actions -->
            <template v-slot:[`item.actions`]="{ item }">
              <template v-if="existInArray('Editar', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="
                        selectLocal(item);
                        showMdlUpdate();
                      "
                      color="amber"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </template>
              <template v-if="existInArray('Eliminar', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectLocal(item);
                        showMdlDelete();
                      "
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
              <template
                v-if="existInArray('Activar/Desactivar', currentPageActions)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectLocal(item);
                        showMdlChangeStatus();
                      "
                      :color="
                        item.status == 1
                          ? 'cyan darken-3'
                          : 'blue-grey darken-1'
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{
                        item.status == 1
                          ? "mdi-eye-outline"
                          : "mdi-eye-off-outline"
                      }}
                    </v-icon>
                  </template>
                  <span>
                    {{ item.status == 1 ? "Desactivar" : "Activar" }}
                  </span>
                </v-tooltip>
              </template>
            </template>
            <!-- end: Acions -->
          </v-data-table>
        </v-card>
        <!-- end: Datatable-->
      </div>
    </div>
    <!-- end::Card -->

    <!-- begin::Modals -->
    <CreateLocalModal
      ref="mdlCreate"
      @add="addOrUpdateData"
      :areas="areas"
    ></CreateLocalModal>

    <UpdateLocalModal
      ref="mdlUpdate"
      :areas="areas"
      @update="addOrUpdateData"
      @clean-selection="unselectLocal"
      :local="selectedLocal"
    ></UpdateLocalModal>

    <DeleteBaseModal ref="mdlDelete" @delete="deleteLocal">
      <template v-slot:title>¿Desea eliminar este local?</template>
      <template v-slot:subject>
        la local
        <span class="font-weight-bolder">{{ selectedLocal.name }}</span>
      </template>
    </DeleteBaseModal>
  </div>
</template>

<script>
import localsRepository from "@/repositories/localsRepository";
import CreateLocalModal from "@/components/modals/locals/CreateLocalModal";
import UpdateLocalModal from "@/components/modals/locals/UpdateLocalModal";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal.vue";
import areasRepository from "@/repositories/areasRepository";
import ChangeStatusBaseModal from "@/components/modals/ChangeStatusBaseModal.vue";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  name: "locals",
  title: "Locales | GE ITR",
  components: {
    CreateLocalModal,
    UpdateLocalModal,
    DeleteBaseModal,
    ChangeStatusBaseModal,
  },
  data() {
    return {
      locals: {
        headers: [
          { text: "Nombre", value: "name" },
          { text: "Descripción", value: "description" },
          {
            text: "Capacidad",
            value: "capacity",
          },
          { text: "Área", value: "Area.name" },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        data: [],
        search: "",
        isLoading: false,
      },
      areas: {
        data: [],
        isLoading: false,
      },
      selectedLocal: {},
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Locales", route: "locals" },
    ]);
    this.loadLocals();
    this.getAreas();
    /*Acá se hace referencia al permiso de las acciones - Despues se creará el de zona*/
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Tipos de duración");
  },
  methods: {
    getAreas() {
      areasRepository
        .getAllAreas()
        .then(({ data }) => {
          this.areas.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.areas.isLoading = false;
        });
    },
    selectLocal(obj) {
      // Creating a non reactive copie of data
      this.selectedLocal = { ...obj };
    },
    unselectLocal() {
      this.selectedLocal = {};
    },
    showMdlCreate() {
      this.$refs.mdlCreate.toggleModal();
    },
    showMdlUpdate() {
      this.$refs.mdlUpdate.toggleModal();
    },
    showMdlDelete() {
      this.$refs.mdlDelete.toggleModal();
    },
    loadLocals() {
      this.locals.isLoading = true;
      localsRepository
        .getAllLocals()
        .then(({ data }) => {
          this.locals.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.locals.isLoading = false;
        });
    },
    deleteLocal() {
      localsRepository
        .deleteLocal(this.selectedLocal.id)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data if operation has been completed
          if (data.status) {
            const filteredData = this.locals.data.filter(
              (item) => item.id !== this.selectedLocal.id
            );
            this.locals.data = filteredData;
          }
        })
        .catch((error) => {
          // Sweetalert that indicates the operation was unsuccesfull
          this.fireToast({
            icon: "error",
            title: error.response.data.message,
          });
        });
    },
    addOrUpdateData({ obj, id }) {
      const data = [...this.locals.data];
      if (!id) {
        data.unshift(obj);
        this.locals.data = data;
        return;
      }

      const modifiedData = data.map((item) => (item.id !== id ? item : obj));
      this.locals.data = modifiedData;
    },
    showImg(item) {
      console.log(item);
    },
  },
  computed: {
    ...mapGetters(["currentPageActions"]),
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
